import * as React from 'react';
import { graphql } from 'gatsby';

import CallOut from '../components/CallOut';
import Categories from '../components/Categories';
import Container from '../components/Container';
import Layout from '../components/Layout';
import Promo from '../components/Promo';

import { Mixer } from '../icons';

const IndexPage = ({ data }) => {
  const { contentfulHomepage, allContentfulPromoBanner, allContentfulProduct } =
    data;
  return (
    <Layout pagetitle="Custom Cakes, Cookies &amp; Cupcakes made in Orange County">
      {allContentfulPromoBanner.edges[0].node.active && (
        <Container>
          <Promo {...allContentfulPromoBanner.edges[0].node} />
        </Container>
      )}

      {contentfulHomepage.body.body && (
        <Container>
          <CallOut icon={<Mixer />}>{contentfulHomepage.body.body}</CallOut>
        </Container>
      )}
      <Container>
        <Categories data={allContentfulProduct.edges} />
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query PromoQuery {
    contentfulHomepage {
      title
      body {
        body
      }
    }
    allContentfulPromoBanner(filter: { active: { eq: true } }) {
      edges {
        node {
          title
          active
          promoImage {
            file {
              url
            }
            title
          }
          body {
            body
          }
          buttonText
          buttonUrl
        }
      }
    }
    allContentfulProduct(sort: { fields: type }) {
      edges {
        node {
          id
          type
          menu
          title
          categoryImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
