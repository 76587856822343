import cx from 'classnames';
import * as React from 'react';

import Button from '../Button';
import FlexContainer from '../FlexContainer';
import Heading from '../Heading';
import Paragraph from '../Paragraph';

import * as styles from './Promo.module.scss';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export const Promo = ({ title, body, buttonText, buttonUrl, promoImage }) => (
  <div className={styles.root}>
    <div className={styles.promoWrapper}>
      <div className={styles.promoText}>
        <FlexContainer flexdirection="column">
          <Heading align="center">{title}</Heading>
          <Paragraph line="2">{body.body}</Paragraph>
          <FlexContainer align="center" justify="center">
            <Button reverse to={buttonUrl}>
              {buttonText}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </div>

      <div className={styles.promoImgContainer}>
        <Carousel
          autoPlay
          centerMode
          centerSlidePercentage="100"
          infiniteLoop
          interval={5000}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          transitionTime={1000}
        >
          {promoImage.map(image => (
            <div key={image.title}>
              <img alt={image.title} src={image.file.url} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  </div>
);
