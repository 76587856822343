import cx from 'classnames';
import * as React from 'react';
import { Link } from 'gatsby';

import Heading from '../Heading';
import * as styles from './Categories.module.scss';

const getImage = ({ details, url }) => {
  const { height, width } = details.image;
  return (
    <div
      className={cx(styles.categoryImg, { [styles.dimension]: height > width })}
      style={{
        backgroundImage: `url('${url}?fm=jpg&fl=progressive&h=300')`,
      }}
    />
  );
};

const makeCategory = ({ node: { id, type, categoryImage, title, menu } }) => (
  <div className={styles.categoryBox} key={id}>
    <Link title={title} to={`/products/${type.toLowerCase()}`}>
      {getImage(categoryImage.file)}
      <div className={styles.categoryTitle}>
        <Heading level={3} lookslike={4} margin="0">
          {menu}
        </Heading>
      </div>
    </Link>
  </div>
);

export const Categories = ({ data }) => (
  <div className={styles.root}>
    <Heading align="center">Stella Sweets Gallery</Heading>
    <div className={styles.categories}>
      {data.map(cat => makeCategory(cat))}
    </div>
  </div>
);
