import cx from 'classnames';
import * as React from 'react';
import * as styles from './Button.module.scss';

import { Link } from 'gatsby';

const getStyles = ({ attached, reverse }) => ({
  [styles.root]: true,
  [styles.attached]: attached,
  [styles.reverse]: reverse,
});

export const Button = props => (
  <>
    {props.to ? (
      <Link className={cx(getStyles(props))} to={props.to}>
        {props.children}
      </Link>
    ) : (
      <button className={cx(getStyles(props))}>{props.children}</button>
    )}
  </>
);
